@import 'variables';

html,
body,
ul,
ol,
h1,h2,h3,h4,h5 {
  margin:  0;
  padding: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
}
html,body{
  height: 100%;
  line-height: 1.5;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root{
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
a{
  text-decoration-color: $black;
  color: $black;
}

button{
  background:none;
  border: none;
}

button:hover{
  cursor: pointer;
}

ul,li{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

select{
  background:transparent;
  border: 0px;
  cursor:pointer;
  text-align:start;
  text-indent:0px;
  text-overflow:ellipsis;
  text-rendering:auto;
  text-shadow:none;
  white-space:pre;
  word-spacing:0px;
  writing-mode:horizontal-tb;
  -webkit-appearance:none;
  -webkit-font-smoothing:antialiased;
  -webkit-rtl-ordering:logical;
  -webkit-border-image:none;
}