@import 'variables';
@import 'reset';
@import 'mixins';


.url-shortener {
  background: $alabaster;

  .ComponentWrapper{
    font-family:$font;
    background: $alabaster;
  }

  header {
    padding-left: $gutter;
    padding-right: $gutter;
    display: flex;
    flex-direction: row;
    align-items: left;
    font-size: calc(10px + 2vmin);
    color: $black;
    margin-bottom:50px;
    margin-top:50px;

  .Icon{
    width:100px;

  }
    h1{
      display: inline-flex;
      text-align: left;
      width: 450px;
      color:$black;
      font-size:30px;
      font-weight:400;
      height:89px;
      line-height:40px;
      align-items: center;
      justify-content: center;
    }
  }


  main{
    justify-content: space-between;
    padding-left:$gutter;
    padding-right:$gutter;

    display: flex;
    flex-wrap: wrap;
    @include mobile{
     flex-direction: column;
    }
  }


  label{
    font-size: 14px;
    margin-right: 5px;
    color:#666;
  }
  .TextField{
    padding-bottom: 30px;
  }


  input{
      background-color:rgba(0, 0, 0, 0);
      border:0;
      border-bottom:1px solid rgba(51, 51, 51, 0.2);
      color:rgb(51, 51, 51);
      display:block;
      font-size:24px;
      font-weight:400;
      height:40px;
      line-height:24px;
      padding-bottom:8px;
      padding-top:8px;
      width:100%;
  }

  button{
      border: 1px solid rgba(51, 51, 51, .2);
      height: 60px;
      min-width: 250px;
      padding: 21px 23px;
      position: relative;
      transition: all 500ms ease;
      background-color: #333;
      font-family: $fontMedium;
      color:#FFFEF2;
      margin-left: auto;
      margin-right:auto;
      display: block;
      font-size: 14px;
      margin-top:30px;
  }

  button:hover{
    background:#000;
  }

  .GeneratedUrl{
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    .UrlBox{
      width: 100%;
      min-height: 25px;
      text-align: center;
      font-size: 14px;
      background-color: $darkAlabaster;
      margin-top:30px;
      padding:25px;
      color: $black;
    }
  }
}