@font-face {
    font-family: 'Suisse Regular';
       src: url('../fonts/SuisseIntl-Regular-WebS.woff2') format('woff2');
       font-style: normal;
       font-weight: normal;
       text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: 'Suisse Medium';
       src: url('../fonts/SuisseIntl-Medium-WebS.woff2') format('woff2');
       font-style: normal;
       font-weight: normal;
       text-rendering: optimizeLegibility;
  }

$mobile-width: 768px;
$tablet-width: 1100px;
$gutter: 4%;
$font: "Suisse Regular", sans-serif;
$fontMedium: "Suisse Medium", sans-serif;
$alabaster: #FFFEF2;
$darkAlabaster:#EBEADE;
$black: rgb(37, 37, 37);
$white: #FFFEF2;
$offWhite: #BCBBB4;


