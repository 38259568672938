@font-face {
  font-family: 'Portrait Web';
  src:
    url('https://aesop.github.io/aesop-gel/assets/fonts/Portrait/Portrait-Regular-Web.woff2') format('woff2'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Portrait/Portrait-Regular-Web.woff') format('woff'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Portrait/Portrait-Regular-Web.eot');
}

@font-face {
  font-family: 'Suisse Regular';
  src:
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-Regular-WebS.woff2') format('woff2'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-Regular-WebS.woff') format('woff'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-Regular-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse Medium';
  src:
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-Medium-WebS.woff2') format('woff2'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-Medium-WebS.woff') format('woff'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-Medium-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse SemiBold';
  src:
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-SemiBold-WebS.woff2') format('woff2'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-SemiBold-WebS.woff') format('woff'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Suisse/SuisseIntl-SemiBold-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'Zapf Humanist';
  src:
    url('https://aesop.github.io/aesop-gel/assets/fonts/Zapf/324B1A_0_0.woff2') format('woff2'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Zapf/324B1A_0_0.woff') format('woff'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Zapf/324B1A_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Canela Light';
  src:
    url('https://aesop.github.io/aesop-gel/assets/fonts/Canela/Canela-Light-Web.woff2') format('woff2'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Canela/Canela-Light-Web.woff') format('woff'),
    url('https://aesop.github.io/aesop-gel/assets/fonts/Canela/Canela-Light-Web.eot');
}
